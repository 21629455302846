<template>
  <div>
    <v-modal-audit-questions-insert v-model="dialogInsert"></v-modal-audit-questions-insert>
    <v-modal-audit-questions-delete :id="selectedIndex" v-model="dialogDelete"></v-modal-audit-questions-delete>
    <v-modal-audit-questions-update :id="selectedIndex" v-model="dialogUpdate"></v-modal-audit-questions-update>

    <v-page-title title="Audit Questions">
      <v-btn 
        elevation="0" 
        color="primary" 
        small 
        dark 
        @click="dialogInsert = true"
      >
        <v-icon left small>mdi-plus</v-icon>
        New Question
      </v-btn>
    </v-page-title>

    <v-card max-width="1200px" class="mx-auto my-5" elevation="0">
      <v-container>
        <v-row dense>
          <v-col>
            <v-loader v-if="!questions"></v-loader>
            <div class="hounddog-table" v-else>
              <v-data-table
                :headers="headers" 
                :items="questions" 
                :items-per-page=30
                item-key="id"
                elevation="0"
                no-data-text="Loading..."
                :mobile-breakpoint="0"
              >
                <template v-slot:item.active="{ item }">
                  <v-switch
                    style="zoom: 0.8"
                    :input-value="item.active"
                    inset
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    @change="toggleQuestion(item.id)"
                    color="green2"
                  ></v-switch>
                </template>
                <template v-slot:item.question="{ item }">
                  <b>{{ item.question }}</b>
                </template>
                <template v-slot:item.sort="{ item }">
                  <b>{{ item.sort }}</b>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn-toggle mandatory background-color="primary" class="ml-2">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small icon color="primary" v-bind="attrs" v-on="on" dark @click="updateDialog(item)">
                          <v-icon small color="white">mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit this</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small icon color="primary" v-bind="attrs" v-on="on" dark @click="deleteDialog(item)">
                          <v-icon small color="white">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete this</span>
                    </v-tooltip>
                  </v-btn-toggle>
                </template>
                <template v-slot:no-data>
                  Loading...
                </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>     
  </div>
</template>

<script>
  export default {
    name: 'AuditQuestions.vue',
    data() {
      return {
        selectedIndex: 0,
        dialogInsert: false,
        dialogDelete: false,
        dialogUpdate: false,
        form:{
          fields: {
            question: '',
            active: 1,
            id: 0
          }
        },
        defaultItem: null,
        headers: [
          { text: "Sort", value: "sort", divider: true, width: '60px', align:'center' },
          { text: "Active", value: "active", sortable: true, width: '80px', align: 'center', divider: true },
          { text: "Question", value: "question", divider: true },
          { text: '', value: 'actions', sortable: false, width: '120px', align:'center', divider: true },
        ],

      }
    },
    computed: {
      questions(){
        return this.$store.getters['auditQuestions/all']
      },
    },
    methods:{
      updateDialog (item) {
        this.selectedIndex = item.id;
        this.dialogUpdate = true;
      },
      deleteDialog (item) {
        this.selectedIndex = item.id
        this.dialogDelete = true;
      },
      async toggleQuestion(id){
        await this.$store.dispatch("auditQuestions/toggle", {id: id});
      },
    }
  }
</script>

<style lang="scss">
</style>